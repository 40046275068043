import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import VueAnalytics from 'vue-analytics'

Vue.use(VueRouter);
const routes = [
  {
      path: "/",
      component: App,
  },
];
const router = new VueRouter({
  routes,
  scrollBehavior () {
      return { x: 0, y: 0 }
  },
});
Vue.use(VueAnalytics, {
  id: 'UA-202034677-1',
  router,
  autoTracking: {
    screenview: true
  }
})
Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
